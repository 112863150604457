import React, { FC } from 'react'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './Impressum.module.less'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { ModalText } from '../ModalText/ModalText'
import { useI18n } from '../../../hooks'
import { Imprint } from '../../../models'
import { HealthCheckImprint } from 'health-check-api'

interface Props {
  scIcImprint: Imprint | null
  hcImprint: HealthCheckImprint | null
}

export const ImpressumContactData: FC<Props> = (props) => {
  const { scIcImprint, hcImprint } = props
  const { i18n } = useI18n()

  return (
    <div>
      {(scIcImprint || hcImprint) && (
        <>
          <h2 style={{ color: CSS_VARS.DARK_BLUE_COLOR, fontFamily: CSS_VARS.fontFamily }}>
            {i18n('xund.imprint.contact')}
          </h2>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="https" className={styles.impressumIcon} />

            <a
              href={scIcImprint ? scIcImprint?.impressum?.companyWebsite.path : hcImprint?.companyWebsite}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#1890ff', fontSize: CSS_VARS.fontSize }}
            >
              {scIcImprint ? scIcImprint?.impressum?.companyWebsite.path : hcImprint?.companyWebsite}
            </a>
          </div>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="envelope" className={styles.impressumIcon} />

            <ModalText
              title={scIcImprint ? scIcImprint?.impressum?.contactEmail.path : hcImprint?.contactEmail}
              style={{ color: 'black' }}
            />
          </div>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="phone" className={styles.impressumIcon} />

            <ModalText
              title={scIcImprint ? scIcImprint?.impressum?.bugReportEmail.path : hcImprint?.bugReportEmail}
              style={{ color: 'black' }}
            />
          </div>

          <div className={styles.impressumItemWithIcon}>
            <CustomIcon icon="address" className={styles.impressumIcon} />

            <ModalText
              title={scIcImprint ? scIcImprint?.impressum?.contactAddress : hcImprint?.contactAddress}
              style={{ color: 'black' }}
            />
          </div>
        </>
      )}
    </div>
  )
}
