import React, { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { useAppStateContext } from '../../context'
import { useApiKeyScopes, useI18n } from '../../hooks'
import { CustomIcon } from '../CustomIcon/CustomIcon'
import { Impressum } from '../Modals/Impressum/Impressum'
import { LanguageSelectionModal } from '../Modals/LanguageSelectionModal/LanguageSelectionModal'
import { RestartModal } from '../Modals/RestartModal/RestartModal'
import styles from '../SideMenu/SideMenu.module.less'
import { HomePageModal } from '../Modals/HomePageModal/HomePageModal'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { MenuItem } from './MenuItem/MenuItem'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { CATEGORY_SIDE_MENU } from '../../models'
import { useCheckIdStorage } from '../../features/healthCheck/hooks/useCheckIdStorage'

/**
 * @param props The props object
 * @param props.onServicesOpen The method handling the opening of services
 * @param props.onHomeClick A method handling the click action on the home icon
 * @param props.onRestartCheck A method handling the restart of the current check
 * @param props.showRestart The boolean which decides whether to show the restart side menu item
 * @returns The SideMenu component
 */
export const SideMenu: FC<{
  onServicesOpen: () => void
  onHomeClick: (onHomePageModalOpen: () => void) => void
  onRestartCheck: () => void
  showRestart: boolean
}> = ({ onServicesOpen, onHomeClick, onRestartCheck, showRestart }) => {
  const servicesEnabled = window.xundEnvironment.SERVICES_ENABLED === 'true'

  const [isHomePageModalOpen, setIsHomePageModalOpen] = useState(false)
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false)
  const [isLanguageSelectModalOpen, setIsLanguageSelectModalOpen] = useState(false)
  const [isImpressumModalOpen, setIsImpressumModalOpen] = useState(false)

  const { hasApiKeyScope } = useApiKeyScopes()

  const {
    isServicesOpen,
    isSideMenuOpen,
    setSideMenuOpen,
    isContentLibraryOpen,
    setContentLibraryOpen,
    isCheckOnlyMode,
    isRestartDisabled,
    setPreventNavigationHookEnabled,
  } = useAppStateContext()

  const { i18n } = useI18n()
  const { trackEvent } = useMatomo()
  const { removeCheckId } = useCheckIdStorage()

  const attachedClasses = useMemo(
    () => (isSideMenuOpen ? [styles.SideDrawer, 'sideBar', styles.Open] : [styles.SideDrawer, 'sideBar', styles.Close]),
    [isSideMenuOpen],
  )

  const handleLanguageButtonClick = useCallback(() => {
    setIsLanguageSelectModalOpen(true)
    trackEvent({
      category: CATEGORY_SIDE_MENU,
      action: 'Language selector clicked',
    })
  }, [trackEvent])

  const handleMedicalLibraryButtonClick = useCallback(() => {
    if (isServicesOpen) {
      onServicesOpen()
    }
    setContentLibraryOpen(!isContentLibraryOpen)
    setSideMenuOpen(!isSideMenuOpen)
    trackEvent({
      category: CATEGORY_SIDE_MENU,
      action: 'Medical library clicked',
    })
  }, [
    isContentLibraryOpen,
    isServicesOpen,
    isSideMenuOpen,
    onServicesOpen,
    setContentLibraryOpen,
    setSideMenuOpen,
    trackEvent,
  ])

  const handleServicesButtonClick = useCallback(() => {
    if (isContentLibraryOpen) {
      setContentLibraryOpen(!isContentLibraryOpen)
    }
    onServicesOpen()
    setSideMenuOpen(!isSideMenuOpen)
    trackEvent({
      category: CATEGORY_SIDE_MENU,
      action: 'Medical services clicked',
    })
  }, [isContentLibraryOpen, isSideMenuOpen, onServicesOpen, setContentLibraryOpen, setSideMenuOpen, trackEvent])

  const handleImpressumButtonClick = useCallback(() => {
    setIsImpressumModalOpen(true)
    setSideMenuOpen(!isSideMenuOpen)
    trackEvent({
      category: CATEGORY_SIDE_MENU,
      action: 'Impressum clicked',
    })
  }, [isSideMenuOpen, setSideMenuOpen, trackEvent])

  return (
    <>
      {!isCheckOnlyMode && !isRestartDisabled && isHomePageModalOpen && (
        <HomePageModal
          onConfirm={() => {
            removeCheckId()
            window.appLocation.reload()
          }}
          onCancel={() => {
            setIsHomePageModalOpen(false)
            setPreventNavigationHookEnabled(true)
          }}
        />
      )}

      {showRestart && !isRestartDisabled && isRestartModalOpen && (
        <RestartModal
          onConfirm={() => {
            onRestartCheck()

            setIsRestartModalOpen(false)
            setSideMenuOpen(!isSideMenuOpen)
          }}
          onCancel={() => setIsRestartModalOpen(false)}
        />
      )}

      {isLanguageSelectModalOpen && <LanguageSelectionModal onCancel={() => setIsLanguageSelectModalOpen(false)} />}

      {isImpressumModalOpen && <Impressum onCancel={() => setIsImpressumModalOpen(false)} />}

      <Fragment>
        {isSideMenuOpen && <div className={styles.background} onClick={() => setSideMenuOpen(false)} />}
        <div className={attachedClasses.join(' ')} style={{ background: CSS_VARS.primaryColor }}>
          <div id="close-menu-btn" className={styles.CancelContainer} onClick={() => setSideMenuOpen(!isSideMenuOpen)}>
            <CustomIcon
              style={{
                fill: CSS_VARS.sideMenuCloseIconColor,
                stroke: CSS_VARS.sideMenuCloseIconColor,
              }}
              icon="cancel"
            />
          </div>

          {!isCheckOnlyMode && !isRestartDisabled && (
            <MenuItem
              onClick={() =>
                onHomeClick(() => {
                  setPreventNavigationHookEnabled(false)
                  setIsHomePageModalOpen(true)
                })
              }
              id="home-menu-item"
              icon="home"
              title={i18n('xund.menu.home')}
            />
          )}

          <MenuItem
            onClick={handleLanguageButtonClick}
            id="change-language-menu-item"
            icon="changeLanguage"
            title={i18n('xund.general.selectLanguage')}
          />

          {hasApiKeyScope('medical_library') && !isCheckOnlyMode && (
            <MenuItem
              onClick={handleMedicalLibraryButtonClick}
              id="content-library-menu-item"
              icon="book"
              title={i18n('xund.menu.medicalLibrary')}
              active={isContentLibraryOpen}
            />
          )}

          {hasApiKeyScope('query_services') && !isCheckOnlyMode && servicesEnabled && (
            <MenuItem
              onClick={handleServicesButtonClick}
              id="services-menu-item"
              icon="healthSolutions"
              title={i18n('xund.services.title')}
              active={isServicesOpen}
            />
          )}

          <MenuItem
            onClick={handleImpressumButtonClick}
            id="impressum-menu-item"
            icon="imprint"
            title={i18n('xund.imprint.title')}
          />

          {showRestart && !isRestartDisabled && (
            <MenuItem
              onClick={() => setIsRestartModalOpen(true)}
              id="restart-menu-item"
              icon="restart"
              title={i18n('xund.menu.restart')}
              style={{ position: 'absolute', bottom: '35px' }}
            />
          )}
        </div>
      </Fragment>
    </>
  )
}
