import styled from '@emotion/styled'
import { CSS_VARS } from '../../resources/cssVariableConfig'

export const ErrorBoundaryContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;
  text-align: center;
`

export const ErrorBoundaryHeader = styled.h1`
  color: ${CSS_VARS.questionFontColor};
  font-family: ${CSS_VARS.fontFamily};
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
`

export const ErrorBoundaryMessage = styled.p`
  color: ${CSS_VARS.textColor};
  font-family: ${CSS_VARS.fontFamily};
  font-size: 14px;
  margin-bottom: 0;
`
