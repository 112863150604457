import React, { FC } from 'react'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { ModalText } from '../ModalText/ModalText'
import { useI18n } from '../../../hooks'
import { HealthCheckImprint } from 'health-check-api'
import { HCCeMark, TextContainer } from './Impressum.styled'

interface Props {
  imprint: HealthCheckImprint
}

export const HealthCheckImpressumData: FC<Props> = (props) => {
  const { imprint } = props
  const { i18n } = useI18n()

  return (
    <>
      <div style={{ position: 'relative' }}>
        <hr></hr>

        <div>
          <h2 style={{ color: CSS_VARS.DARK_BLUE_COLOR, fontFamily: CSS_VARS.fontFamily }}>
            {i18n('healthCheck.imprint.medicalDevice')}
          </h2>
          <TextContainer>
            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.product_name')}: ${imprint.productName}`}
              style={{ color: 'black' }}
            />
            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.interface')}: ${imprint.interface}`}
              style={{ color: 'black' }}
            />
            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.UDI_DI')}: ${imprint.udiId}`}
              style={{ color: 'black' }}
            />
            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.basic_UDI_DI')}: ${imprint.basicUdiId}`}
              style={{ color: 'black' }}
            />
            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.UDI_PI_date_of_manufacture')}: ${imprint.productionDate}`}
              style={{ color: 'black' }}
            />
            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.UDI_PI_software_version')}: ${imprint.softwareVersion}`}
              style={{ color: 'black' }}
            />
          </TextContainer>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <HCCeMark dangerouslySetInnerHTML={{ __html: imprint.ceMark }} />
      </div>
    </>
  )
}
