import styled from '@emotion/styled'

export const HCCeMark = styled.div`
  svg {
    height: 27px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`
