import React, { FC } from 'react'
import { CustomIcon } from '../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { ErrorBoundaryContainer, ErrorBoundaryHeader, ErrorBoundaryMessage } from './ErrorBoundary.styled'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { useI18n } from '../../hooks'

export const ErrorScreen: FC = () => {
  const { i18n } = useI18n()

  return (
    <ErrorBoundaryContainer>
      <CustomIcon icon="gear" style={{ fill: undefined, color: CSS_VARS.questionFontColor, marginBottom: 30 }} />
      <ErrorBoundaryHeader>{i18n('errors.general.title')}</ErrorBoundaryHeader>
      <ErrorBoundaryMessage>{i18n('errors.general.description')}</ErrorBoundaryMessage>
      <StyledButton
        title={i18n('errors.general.restart')}
        type="confirm"
        onClick={() => {
          window.location.reload()
        }}
        style={{ marginTop: 40 }}
      />
    </ErrorBoundaryContainer>
  )
}
