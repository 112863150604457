import { z } from 'zod'

const zPatientIntentAnswerDTO = z.object({
  id: z.string().uuid(),
  text: z.string(),
})
export const zPatientIntentQuestionDTO = z.object({
  id: z.string().uuid(),
  text: z.string(),
  answers: zPatientIntentAnswerDTO.array(),
})

export type PatientIntentQuestion = z.infer<typeof zPatientIntentQuestionDTO>
