import React, { FC, ReactNode, useState, useMemo, CSSProperties } from 'react'

import { Dialog } from './Dialog'

import { useToggle, useWindowSize } from 'usehooks-ts'
import { useI18n, mobileMaxWidth } from '../../../hooks'
import styles from './BaseModal.module.less'
import styled from '@emotion/styled'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

import { StyledButton } from '../../General/StyledButton/StyledButton'
import { CloseButton } from './CloseButton'
import ReactDOM from 'react-dom'

const StyledTitle = styled(Dialog.Title)<{ isImpressumModal: boolean }>`
  color: ${(props) => (props.isImpressumModal ? CSS_VARS.DARK_BLUE_COLOR : CSS_VARS.textColor)};
`

/**
 *
 * @param props The props object
 * @param props.title The title text or element to display in the Modal
 * @param props.children The main content of the Modal
 * @param props.onOk Method handling the OK button press
 * @param props.onCancel The method handling cancellation
 * @param props.okBtnId Optional ID for the OK button
 * @param props.okBtnTitle Optional custom text for the OK button
 * @param props.okBtnDisabled Logic to determine if the OK button is disabled or not
 * @param props.cancelBtnId Optional ID for the Cancel button
 * @param props.cancelBtnTitle Optional custom text for the Cancel button
 * @param props.cancelBtnDisabled Logic to determine if the Cancel button is disabled or not
 * @param props.onOpenChange Optional method that is called when the Modal is opened or closed
 * @param props.actionAlignment Vertical alignment of the Buttons
 * @param props.actionJustify Horizontal alignment of the Buttons
 * @param props.hasButtons This boolean decides whether we display buttons or not
 * @param props.contentStyle Optional custom style to add to the content of the Modal
 * @param props.contentClassName Optional custom class name for the content of the Modal
 * @returns The BaseModal component
 */
export const BaseModal: FC<{
  title?: string | ReactNode
  children?: ReactNode
  onOk?: Function
  onCancel: Function
  okBtnId?: string
  okBtnTitle?: string
  okBtnDisabled?: boolean
  cancelBtnId?: string
  cancelBtnTitle?: string
  cancelBtnDisabled?: boolean
  onOpenChange?: Function
  actionAlignment?: 'start' | 'center' | 'end' | 'stretch' | 'baseline'
  actionJustify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly'
  hasButtons?: boolean
  isImpressumModal?: boolean
  contentStyle?: CSSProperties
  contentClassName?: string
}> = ({
  title,
  children,
  onOk,
  onCancel,
  okBtnId,
  okBtnTitle,
  okBtnDisabled,
  cancelBtnId,
  cancelBtnTitle,
  cancelBtnDisabled,
  onOpenChange,
  actionAlignment = 'center',
  actionJustify = 'center',
  hasButtons = true,
  isImpressumModal = false,
  contentStyle,
  contentClassName,
}) => {
  const { i18n } = useI18n()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, toggleOpen] = useToggle(true)
  const { width: windowWidth } = useWindowSize()
  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  const portalId = 'xund-modal'
  let modalPortalTarget = document.getElementById(portalId)

  // create portal target element for modal if it's not created yet
  // inserting it as the last child of #root enables to blur the page behind the modal
  if (!modalPortalTarget) {
    const root = document.getElementById('root')
    modalPortalTarget = document.createElement('div')
    modalPortalTarget.id = portalId
    root?.appendChild(modalPortalTarget)
  }

  return ReactDOM.createPortal(
    <Dialog.Root
      open={isOpen}
      onOpenChange={() => {
        onOpenChange ? onOpenChange() : onCancel()
        toggleOpen()
      }}
    >
      <Dialog.Content id={styles.baseModal}>
        <StyledTitle isImpressumModal={isImpressumModal}>{title}</StyledTitle>

        <div className={[styles.content, contentClassName].join(' ')} style={contentStyle}>
          {children}
        </div>
        {hasButtons && (
          <Dialog.Actions
            align={actionAlignment}
            justify={actionJustify}
            direction={isMobile ? 'column-reverse' : 'row'}
          >
            <StyledButton
              id={cancelBtnId}
              className={styles.button}
              disabled={isLoading || cancelBtnDisabled}
              onClick={() => onCancel()}
              type="skip"
              title={cancelBtnTitle ?? i18n('xund.general.modal.cancel')}
              classNames={[styles.emergencyButton]}
            />

            {onOk ? (
              <StyledButton
                id={okBtnId}
                type="filled"
                title={okBtnTitle ?? i18n('xund.general.modal.submit')}
                disabled={isLoading || okBtnDisabled}
                onClick={async () => {
                  setIsLoading(true)
                  await onOk()
                }}
                classNames={[styles.emergencyButton]}
              />
            ) : (
              <></>
            )}
          </Dialog.Actions>
        )}
        <CloseButton
          onClick={() => {
            onCancel()
          }}
        />
        {!hasButtons && <div className={styles.bottomMargin}></div>}
      </Dialog.Content>
    </Dialog.Root>,
    modalPortalTarget,
  )
}
