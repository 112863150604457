import React, { FC, useMemo, useState } from 'react'
import DefaultXUNDNavbarLogo from '../../assets/images/xund_logo.svg'
import {
  CHECK_TYPE_HEALTH_CHECK,
  CHECK_TYPE_ILLNESS_CHECK,
  CHECK_TYPE_SYMPTOM_CHECK,
  useAppStateContext,
  useWebAppConfigContext,
} from '../../context'
import { ImageWithFallback } from '../General/ImageWithFallback/ImageWithFallback'
import { HomePageModal } from '../Modals/HomePageModal/HomePageModal'
import { SideMenuToggle } from './SideMenuToggle/SideMenuToggle'
import { useWindowSize } from 'usehooks-ts'
import { tabletMaxWidth, useI18n } from '../../hooks'
import { Logo, ProgressBar, StatusLabel, StatusRow, StyledHeader } from './Header.styled'
import { CheckPhaseStep, CheckType, CATEGORY_CHECK_RESTARTED } from '../../models'
import { StatusStep } from '../../features/healthCheck/models'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useCheckIdStorage } from '../../features/healthCheck/hooks/useCheckIdStorage'

type Status = {
  name: string
  isActive: boolean
}

interface Props {
  onHomeClick: (onHomePageModalOpen: () => void) => void
  checkType: CheckType
  healthCheckProgressPercent: number
  scIcStep: CheckPhaseStep
  healthCheckStep: StatusStep
}

export const Header: FC<Props> = (props) => {
  const { onHomeClick, checkType, healthCheckStep, scIcStep, healthCheckProgressPercent } = props
  const { webAppConfig } = useWebAppConfigContext()
  const { isRestartDisabled, setPreventNavigationHookEnabled } = useAppStateContext()
  const [isHomePageModalOpen, setIsHomePageModalOpen] = useState(false)
  const { i18n } = useI18n()
  const { trackEvent } = useMatomo()
  const { removeCheckId } = useCheckIdStorage()

  const { width: windowWidth } = useWindowSize()
  const isTabletOrSmaller = useMemo(() => windowWidth <= tabletMaxWidth, [windowWidth])

  const statuses: Status[] = useMemo(() => {
    if (isTabletOrSmaller) {
      return []
    }
    if (checkType === CHECK_TYPE_HEALTH_CHECK) {
      const statusNames = [
        `01. ${i18n('healthCheck.status.introduction')}`,
        `02. ${i18n('healthCheck.status.general')}`,
        `03. ${i18n('healthCheck.status.medicalHistoryAndLifestyle')}`,
        `04. ${i18n('healthCheck.status.familyHistory')}`,
        `05. ${i18n('healthCheck.status.report')}`,
      ]
      return statusNames.map((name, index) => ({ name, isActive: healthCheckStep >= index }))
    }
    if ([CHECK_TYPE_ILLNESS_CHECK, CHECK_TYPE_SYMPTOM_CHECK].includes(checkType)) {
      const statusNames = [
        `01. ${i18n('xund.general.profile')}`,
        `02. ${i18n('xund.general.symptoms')}`,
        `03. ${i18n('xund.general.healthData')}`,
        `04. ${i18n('xund.general.report')}`,
      ]
      return statusNames.map((name, index) => ({ name, isActive: scIcStep >= index + 1 }))
    }
    return []
  }, [isTabletOrSmaller, checkType, healthCheckStep, i18n, scIcStep])

  const progressPercent = useMemo(() => {
    if ([CHECK_TYPE_ILLNESS_CHECK, CHECK_TYPE_SYMPTOM_CHECK].includes(checkType)) {
      switch (scIcStep) {
        case 0:
          return 0
        case 1:
          return 25
        case 2:
          return 50
        case 3:
          return 75
        case 4:
          return 100
        default:
          return 0
      }
    }
    if (checkType === CHECK_TYPE_HEALTH_CHECK) {
      return healthCheckProgressPercent
    }
    return 0
  }, [scIcStep, checkType, healthCheckProgressPercent])

  return (
    <>
      {isHomePageModalOpen && !isRestartDisabled && (
        <HomePageModal
          onConfirm={() => {
            trackEvent({
              category: CATEGORY_CHECK_RESTARTED,
              action: 'Check restarted',
            })
            removeCheckId()
            window.appLocation.reload()
          }}
          onCancel={() => {
            setPreventNavigationHookEnabled(true)
            setIsHomePageModalOpen(false)
          }}
        />
      )}
      <StyledHeader id="header">
        <Logo>
          <ImageWithFallback
            imageToDisplay={webAppConfig.navBarLogo ?? DefaultXUNDNavbarLogo}
            fallbackImage={DefaultXUNDNavbarLogo}
            onClick={() =>
              onHomeClick(() => {
                if (isRestartDisabled) {
                  return true
                }
                setPreventNavigationHookEnabled(false)
                setIsHomePageModalOpen(true)
              })
            }
          />
        </Logo>
        <StatusRow>
          {statuses.map((status, key) => (
            <StatusLabel key={`status-${key}`} isactive={status.isActive.toString()}>
              {status.name}
            </StatusLabel>
          ))}
        </StatusRow>
        <SideMenuToggle />
        {checkType && <ProgressBar progressPercent={progressPercent} />}
      </StyledHeader>
    </>
  )
}
