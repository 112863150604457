import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React, { CSSProperties, FC, useMemo } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { mobileMaxWidth } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator'

import { StyledButtonStyled, LoadingIndicatorContainer } from './StyledButton.styled'

type ButtonType = 'outlined' | 'filled' | 'confirm' | 'skip'

//TODO refactor component using styled
export const StyledButton: FC<{
  id?: string
  className?: string
  testId?: string
  title: string
  type: ButtonType
  size?: SizeType
  style?: CSSProperties
  classNames?: string[]
  disabled?: boolean
  onClick?: () => void
  isLoading?: boolean
  autoFocus?: boolean
}> = ({ testId, title, type, size, style, classNames, disabled, onClick, isLoading, autoFocus }) => {
  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  const buttonSize = useMemo(() => {
    if (type === 'outlined') {
      return CSS_VARS.buttonSize
    }
    if (type === 'filled') {
      return CSS_VARS.buttonSize
    }
    if (type === 'confirm') {
      return CSS_VARS.buttonSize
    }
    if (type === 'skip') {
      return CSS_VARS.buttonSize
    }
    return 'middle'
  }, [type])

  const buttonType = useMemo(() => {
    if (type === 'outlined' || type === 'skip') {
      return 'default'
    }
    if (type === 'filled' || type === 'confirm' || type === 'delete') {
      return 'primary'
    }
    return 'default'
  }, [type])

  return (
    <StyledButtonStyled
      data-testid={testId}
      type={buttonType}
      buttontype={type}
      size={size ?? (buttonSize as SizeType)}
      style={style}
      isloading={+(isLoading ?? 0)}
      ismobile={+isMobile}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={classNames ? classNames.join(' ') : undefined}
      autoFocus={autoFocus}
    >
      {!isLoading && title}
      {isLoading && (
        <LoadingIndicatorContainer>
          <LoadingIndicator color={type === 'filled' ? 'secondary' : 'primary'} />
        </LoadingIndicatorContainer>
      )}
    </StyledButtonStyled>
  )
}
