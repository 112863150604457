import { useCallback } from 'react'

export const useCheckIdStorage = () => {
  const removeCheckId = useCallback(() => {
    localStorage.removeItem('checkID')
    localStorage.removeItem('checkType')
  }, [])

  return { removeCheckId }
}
