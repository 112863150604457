import React, { FC } from 'react'
import styles from './Impressum.module.less'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { ModalText } from '../ModalText/ModalText'
import { Typography } from 'antd'
import { Imprint } from '../../../models'
import { useI18n, useLanguage } from '../../../hooks'

interface Props {
  imprint: Imprint
}

const USER_MANUALS = {
  en: 'https://xund.ai/06-user-manual',
  de: 'https://xund.ai/06-benutzerhandbuch',
  'de-formal': 'https://xund.ai/06-benutzerhandbuch',
  fr: 'https://xund.ai/fr/user-manual',
  'fr-formal': 'https://xund.ai/fr/user-manual',
  hu: 'https://xund.ai/hu/user-manual',
  nl: 'https://xund.ai/nl/user-manual',
  'nl-formal': 'https://xund.ai/nl/user-manual',
  it: 'https://xund.ai/it/user-manual',
  'it-formal': 'https://xund.ai/it/user-manual',
}

export const ScIcImpressumData: FC<Props> = (props) => {
  const { imprint } = props
  const { i18n } = useI18n()
  const { currentLanguage } = useLanguage()

  return (
    <>
      <div className={styles.impressumContainer}>
        <hr></hr>

        <div className={styles.impressumInformation}>
          <h2 style={{ color: CSS_VARS.DARK_BLUE_COLOR, fontFamily: CSS_VARS.fontFamily }}>
            {i18n('xund.imprint.medicalProductInfo')}
          </h2>

          <div>
            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.product_name')}: ${
                imprint?.medicalProductInfo?.productName
              }`}
              style={{ color: 'black' }}
            />

            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.interface')}: ${imprint?.medicalProductInfo?.interface}`}
              style={{ color: 'black' }}
            />

            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.UDI_DI')}: ${imprint?.medicalProductInfo?.udiDi}`}
              style={{ color: 'black' }}
            />

            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.basic_UDI_DI')}: ${
                imprint?.medicalProductInfo?.basicUdiDi
              }`}
              style={{ color: 'black' }}
            />

            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.UDI_PI_date_of_manufacture')}: ${
                imprint?.medicalProductInfo?.udiPiDateOfManufacture
              }`}
              style={{ color: 'black' }}
            />

            <ModalText
              title={`${i18n('xund.imprint.medicalProductInfo.UDI_PI_software_version')}: ${
                imprint?.medicalProductInfo?.udiPiSoftwareVersion
              }`}
              style={{ color: 'black' }}
            />

            <Typography>
              <a
                href={USER_MANUALS[currentLanguage]}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#1890ff', fontSize: CSS_VARS.fontSize }}
              >
                {i18n('xund.imprint.manual')}
              </a>
            </Typography>
          </div>
        </div>
      </div>

      {imprint?.medicalProductInfo.ceMark && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <img src={imprint.medicalProductInfo.ceMark.path} alt="CE Mark" style={{ height: 27 }} />
        </div>
      )}
    </>
  )
}
